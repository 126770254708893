import { AfterViewInit, Component, OnInit } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { FormGroup, FormControl } from "@angular/forms";
import { Router } from "@angular/router";
import { BookingService } from "src/app/services/booking.service";
import { JarwisService } from "src/app/services/jarwis.service";
import Swal from "sweetalert2";

@Component({
  selector: "app-room-search",
  templateUrl: "./search.component.html",
  styleUrls: ["./search.component.css"],
})
export class SearchComponent implements OnInit, AfterViewInit {
  public error = [];
  reserveForm: FormGroup;
  selectedBooking: [];

  constructor(
    private router: Router,
    private Jarwis: JarwisService,
    private Booking: BookingService,
    public fb: FormBuilder
  ) {}

  ngAfterViewInit() {
    let data_source = localStorage.getItem('booking_dates');
    console.log(data_source);
  }

  ngOnInit() {
    this.Booking.currentMessage.subscribe(
      (res) => (this.selectedBooking = res)
    );
    let data_source = JSON.parse(localStorage.getItem('booking_dates'));
    console.log(data_source);
    if (data_source !== null) {
      console.log(this.selectedBooking);
      this.reserveForm = this.fb.group({
        checkin_date: new FormControl(
          data_source["checkin_date"]
        ),
        checkout_date: new FormControl(
          data_source["checkout_date"]
        ),
        // room_type: new FormControl(this.selectedBooking['search']['']),
        adults: new FormControl(
          data_source["adults"]
        ),
        kids: new FormControl(
          data_source["kids"]
        ),
      });
    } else {
      this.reserveForm = this.fb.group({
        checkin_date: new FormControl(""),
        checkout_date: new FormControl(""),
        // room_type: new FormControl(''),
        adults: new FormControl(1),
        kids: new FormControl(0),
      });
    }
  }
  dateConvert(req_date) {
    var date = new Date(req_date);
    var year = date.getFullYear();
    var month = date.getMonth() + 1; //getMonth is zero based;
    var day = date.getDate();
    var formatted = year + "/" + month + "/" + day;
    return formatted;
  }

  onSubmit(nform: FormGroup) {
    console.log("Valid?", nform.valid); // true or false
    console.log("checkin_date", nform.value.kids);
    console.log("Email", nform.value.adults);
    // const url = 'room-list?arrival=' + this.dateConvert(nform.value.checkin_date) + '&departure=' + this.dateConvert(nform.value.checkout_date) + '&children=' + nform.value.kids + '&adults=' + nform.value.adults;
    const url =
      "room-list?search[arrival]=" +
      encodeURIComponent(this.dateConvert(nform.value.checkin_date)) +
      "&search[departure]=" +
      encodeURIComponent(this.dateConvert(nform.value.checkout_date)) +
      "&search[adults]=" +
      nform.value.adults +
      "&search[children]=" +
      nform.value.kids;
    // console.log('Form successful submit.');
    // console.log(productForm.value);
    localStorage.setItem('booking_dates', JSON.stringify(nform.value));
    // this.Jarwis.postURI( url, nform.value).subscribe(res => {
    this.Jarwis.getURI(url).subscribe(
      (res) => {
        let params = {
          search_res: JSON.stringify(res["data"]),
        };
        if (res["status"] === 200) {
          this.Booking.changeMessage(nform);
          Swal.fire("Accomodation ", "is available !", "success").then(
            function () {
              // this.router.navigate(["rooms"]);
            }
          );
          this.router.navigate(['rooms']);
        } else {
          Swal.fire("Failed!", "Something went wrong", "warning");
        }
        // this.reserveForm.reset();
      },
      (err) => {
        console.log("Error occured");
      }
    );
  }
}
