import { Component } from '@angular/core';
import { ScriptService } from './services/script.service';
import { Router, NavigationEnd } from '@angular/router';

declare let ga: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'hotel';
  constructor(
    public script: ScriptService,
    public router: Router
  ) { 
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        ga('set', 'page', event.urlAfterRedirects);
        ga('send', 'pageview');

      }
    });
    this.load();
  }

  load() {
    /* this.script.load(
      'jquery',
      'migrate',
      'popper',
      'bootstrapjs',
      'easing',
      'waypoints',
      'waypoints',
      'stellar',
      'bdatepicker',
      'jtimepicker',
      'scrollax',
      'aos',
      'google-map',
      'main'
      ).then(data => {
      console.log('Script loaded ', data);
    }).catch(error => console.log(error)); */
  }
}
