import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-payment-preview',
  templateUrl: './payment-preview.component.html',
  styleUrls: ['./payment-preview.component.css']
})
export class PaymentPreviewComponent implements OnInit {
  protected _id: string;

  constructor(
    private _Activatedroute:ActivatedRoute,
  ) { }

  ngOnInit() {
    this._id=this._Activatedroute.snapshot.paramMap.get("id");
  }

}
