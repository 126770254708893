import { Injectable,Inject } from '@angular/core';
import { ReplaySubject, Observable, forkJoin } from 'rxjs';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class LibraryService {
  private _loadedLibraries: { [url: string]: ReplaySubject<any> } = {};


  constructor(@Inject(DOCUMENT) private readonly document: any) { }
  
  lazyLoadLibraries(): Observable<any> {
    return forkJoin([
      this.loadScript('/assets/islagrande/js/main.js'),
      this.loadStyle('/assets/islagrande/css/style.css')
    ]);
  }

  lazyLoadLibrariesBack(): Observable<any> {
    return forkJoin([
      this.loadScript('/assets/islagrande/js/jquery.min.js'),
      this.loadScript('/assets/islagrande/js/jquery-migrate-3.0.1.min.js'),
      this.loadScript('/assets/islagrande/js/popper.min.js'),
      this.loadScript('/assets/islagrande/js/bootstrap.min.js'),
      this.loadScript('/assets/islagrande/js/jquery.easing.1.3.js'),
      this.loadScript('/assets/islagrande/js/jquery.waypoints.min.js'),
      this.loadScript('/assets/islagrande/js/jquery.stellar.min.js'),
      this.loadScript('/assets/islagrande/js/owl.carousel.min.js'),
      this.loadScript('/assets/islagrande/js/jquery.magnific-popup.min.js'),
      this.loadScript('/assets/islagrande/js/aos.js'),
      this.loadScript('/assets/islagrande/js/jquery.animateNumber.min.js'),
      this.loadScript('/assets/islagrande/js/bootstrap-datepicker.js'),
      // this.loadScript('/assets/islagrande/js/jquery.timepicker.min.js'),
      this.loadScript('/assets/islagrande/js/scrollax.min.js'),
      // this.loadScript('/assets/islagrande/js/google-map.js'),
      this.loadScript('/assets/islagrande/js/main.js'),
      // this.loadStyle('/assets/islagrande/css/style.css')
    ]);
  }


  lazyJqueryLibraries(): Observable<any> {
    return forkJoin([
      this.loadScript('/assets/islagrande/js/jquery-migrate-3.0.1.min.js'),
      this.loadScript('/assets/islagrande/js/popper.min.js'),
      this.loadScript('/assets/islagrande/js/bootstrap.min.js'),
      this.loadScript('/assets/islagrande/js/jquery.easing.1.3.js'),
      this.loadScript('/assets/islagrande/js/jquery.waypoints.min.js'),
      this.loadScript('/assets/islagrande/js/jquery.stellar.min.js'),
      this.loadScript('/assets/islagrande/js/owl.carousel.min.js'),
      this.loadScript('/assets/islagrande/js/jquery.magnific-popup.min.js'),
      this.loadScript('/assets/islagrande/js/aos.js'),
      this.loadScript('/assets/islagrande/js/jquery.animateNumber.min.js'),
      this.loadScript('/assets/islagrande/js/bootstrap-datepicker.js'),
      // this.loadScript('/assets/islagrande/js/jquery.timepicker.min.js'),
      this.loadScript('/assets/islagrande/js/scrollax.min.js'),
      // this.loadScript('/assets/islagrande/js/google-map.js'),
      // this.loadScript('/assets/islagrande/js/main.js'),
      // this.loadStyle('/assets/islagrande/css/style.css')
    ]);
  }

  private loadScript(url: string): Observable<any> {
    if (this._loadedLibraries[url]) {
      return this._loadedLibraries[url].asObservable();
    }

    this._loadedLibraries[url] = new ReplaySubject();

    const script = this.document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.src = url;
    script.onload = () => {
      this._loadedLibraries[url].next();
      this._loadedLibraries[url].complete();
    };

    this.document.body.appendChild(script);

    return this._loadedLibraries[url].asObservable();
  }

  private loadStyle(url: string): Observable<any> {
    if (this._loadedLibraries[url]) {
      return this._loadedLibraries[url].asObservable();
    }

    this._loadedLibraries[url] = new ReplaySubject();

    const style = this.document.createElement('link');
    style.type = 'text/css';
    style.href = url;
    style.rel = 'stylesheet';
    style.onload = () => {
      this._loadedLibraries[url].next();
      this._loadedLibraries[url].complete();
    };

    const head = document.getElementsByTagName('head')[0];
    head.appendChild(style);

    return this._loadedLibraries[url].asObservable();
  }
}
