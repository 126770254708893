import { Component, OnInit, Inject, AfterViewInit } from '@angular/core';
import { ScriptService } from '../services/script.service';
import { LibraryService } from '../services/library.service';
import { DOCUMENT } from '@angular/common';
import { Router, NavigationEnd } from '@angular/router';

declare let ga: Function;
declare var Quill: any;

@Component({
  selector: 'app-theme',
  templateUrl: './theme.component.html',
  styleUrls: ['./theme.component.css']
})

export class ThemeComponent implements AfterViewInit {

  constructor(
    public script: ScriptService,
    public router: Router,
    private readonly svc: LibraryService,
    @Inject(DOCUMENT) private readonly document: any
  ) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        ga('set', 'page', event.urlAfterRedirects);
        ga('send', 'pageview');

      }
    });
  }

  ngOnInit() {
    // this.svc.lazyJqueryLibraries().subscribe(_ => {
    // });

    /* this.script.load(
			'jquery',
			'migrate',
			'popper',
			'bootstrapjs',
			'easing',
			'waypoints',
			'stellar',
			'aos',
			'bdatepicker',
			'scrollax',
			'main'
      ).then(data => {
      console.log('Script loaded ', data);
    }).catch(error => console.log(error)); */

    console.log('theme rendered');
  }

  ngAfterViewInit() {
  }
  
  onActivate(event) {
    // tslint:disable-next-line:prefer-const
    let scrollToTop = window.setInterval(() => {
        // tslint:disable-next-line:prefer-const
        let pos = window.pageYOffset;
        if (pos > 0) {
            window.scrollTo(0, pos - 20); // How far to scroll on each step
        } else {
            window.clearInterval(scrollToTop);
        }
    }, 16);
  }

}
