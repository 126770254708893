import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { SearchComponent } from './search/search.component';
import { ReservationFormComponent } from './reservation-form/reservation-form.component';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { Ng2IziToastModule } from 'ng2-izitoast';
import { OwlModule } from 'ngx-owl-carousel';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [HeaderComponent, FooterComponent, SearchComponent, ReservationFormComponent],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    Ng2IziToastModule,
    OwlModule,
    OwlDateTimeModule, 
    OwlNativeDateTimeModule
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
   exports: [
    HeaderComponent,
    FooterComponent,
    SearchComponent,
    ReservationFormComponent
  ]
})
export class UiModule { }
