import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ThemeRoutingModule } from './theme-routing.module';
import { ThemeComponent } from './theme.component';
import { HomeComponent } from './home/home.component';
import { Ng2IziToastModule } from 'ng2-izitoast';
import { UiModule } from './ui/ui.module';
import { AboutComponent } from './about/about.component';
import { ContactComponent } from './contact/contact.component';
import { OwlModule } from 'ngx-owl-carousel';
import { RoomsComponent } from './rooms/rooms.component';
import { RestaurantComponent } from './restaurant/restaurant.component';
import { ServicesComponent } from './services/services.component';
import { ComponentModule } from './component/component.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { BookingComponent } from './booking/booking.component';
import { ReservationComponent } from './reservation/reservation.component';
import { CheckoutComponent } from './reservation/checkout/checkout.component';
import { PaymentComponent } from './payment/payment.component';
import { PreviewComponent } from './payment/preview/preview.component';
import { ConfirmComponent } from './payment/confirm/confirm.component';
import { RoomDetailsComponent } from './rooms/room-details/room-details.component';
import { DetailsComponent } from './reservation/details/details.component';
import { PaymentGatewaysComponent } from './reservation/payment-gateways/payment-gateways.component';
import { PaymentPreviewComponent } from './reservation/payment-preview/payment-preview.component';

@NgModule({
  declarations: [ThemeComponent, HomeComponent, AboutComponent, ContactComponent, RoomsComponent, RestaurantComponent, ServicesComponent, BookingComponent, ReservationComponent, CheckoutComponent, PaymentComponent, PreviewComponent, ConfirmComponent, RoomDetailsComponent, DetailsComponent, PaymentGatewaysComponent, PaymentPreviewComponent],
  imports: [
    CommonModule,
    ThemeRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    UiModule,
    ComponentModule,
    Ng2IziToastModule,
    OwlModule,
    OwlDateTimeModule, 
    OwlNativeDateTimeModule
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  exports: [
    ThemeComponent
  ]
})
export class ThemeModule { }
