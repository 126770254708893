import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { ThemeComponent } from './theme.component';
import { AboutComponent } from './about/about.component';
import { ContactComponent } from './contact/contact.component';
import { RestaurantComponent } from './restaurant/restaurant.component';
import { RoomsComponent } from './rooms/rooms.component';
import { ServicesComponent } from './services/services.component';
import { DetailsComponent } from './reservation/details/details.component';
import { CheckoutComponent } from './reservation/checkout/checkout.component';
import { PaymentGatewaysComponent } from './reservation/payment-gateways/payment-gateways.component';
import { PaymentPreviewComponent } from './reservation/payment-preview/payment-preview.component';

const routes: Routes = [{
  path: '',
  component: ThemeComponent,
  children: [
    // routes here
    // { path: '', redirectTo: 'index', pathMatch: 'prefix' },
    { path: '',
      component: HomeComponent,
    },
    { path: 'about',
      component: AboutComponent,
    },
    { path: 'checkout',
      component: CheckoutComponent,
    },
    { path: 'contact',
      component: ContactComponent,
    },
    { path: 'home',
      component: HomeComponent,
    },
    {
      path: 'reservation/:id',
      component: DetailsComponent,
    },
    {
      path: 'payments/select-gateway',
      component: PaymentGatewaysComponent,
    },
    {
      path: 'payments/payment-preview/:id',
      component: PaymentPreviewComponent,
    },
    {
      path: 'restaurant',
      component: RestaurantComponent,
    },
    {
      path: 'rooms',
      component: RoomsComponent,
    },
    {
      path: 'services',
      component: ServicesComponent
    }
  ]
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ThemeRoutingModule { }
