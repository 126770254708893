import { AfterViewInit, Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
declare var jQuery: any;

@Component({
  selector: "app-checkout",
  templateUrl: "./checkout.component.html",
  styleUrls: ["./checkout.component.css"],
})
export class CheckoutComponent implements OnInit, AfterViewInit {
  reservation_data: any;
  room_type: any;
  constructor(private router: Router) {}

  ngAfterViewInit() {
    this.loadScript();
    // throw new Error('Method not implemented.');
  }

  ngOnInit() {
    /**
     * 
    this.reservation_data['phone'] = "Carl Stanley";
    this.reservation_data['email'] = "Carl Stanley";
    this.reservation_data['name'] = "Carl Stanley";
    this.room_type['title'] = "Carl Stanley";
     */
    let reserve_data = JSON.parse(localStorage.getItem("reservation"));
    this.reservation_data = reserve_data["data"];
    console.log(this.reservation_data);
  }

  confirmCheckout() {
    if (this.reservation_data !== null) {
      this.router.navigate(["/payments/select-gateway"]);
    } else {
      this.router.navigate(["/rooms"]);
    }
  }

  dateConvert(req_date) {
    var date = new Date(req_date);
    var year = date.getFullYear();
    var month = date.getMonth() + 1; //getMonth is zero based;
    var day = date.getDate();
    var formatted = year + "/" + month + "/" + day;
    return formatted;
  }

  loadScript() {
    (function ($) {
      "use strict";

      $(window).stellar({
        responsive: true,
        parallaxBackgrounds: true,
        parallaxElements: true,
        horizontalScrolling: false,
        hideDistantElements: false,
        scrollProperty: "scroll",
        horizontalOffset: 0,
        verticalOffset: 0,
      });

      var fullHeight = function () {
        $(".js-fullheight").css("height", $(window).height());
        $(window).resize(function () {
          $(".js-fullheight").css("height", $(window).height());
        });
      };
      fullHeight();

      var halfHeight = function () {
        $(".js-halfheight").css("height", $(window).height() / 3);
        $(window).resize(function () {
          $(".js-halfheight").css("height", $(window).height() / 3);
        });
      };
      halfHeight();

      // loader
      var loader = function () {
        setTimeout(function () {
          if ($("#ftco-loader").length > 0) {
            $("#ftco-loader").removeClass("show");
          }
        }, 1);
      };
      loader();

      // Scrollax
      $.Scrollax();

      var carousel = function () {
        $(".home-slider").owlCarousel({
          loop: true,
          autoplay: true,
          margin: 0,
          animateOut: "fadeOut",
          animateIn: "fadeIn",
          nav: true,
          autoplayHoverPause: false,
          items: 1,
          navText: [
            "<span class='ion-md-arrow-back'></span>",
            "<span class='ion-chevron-right'></span>",
          ],
          responsive: {
            0: {
              items: 1,
            },
            600: {
              items: 1,
            },
            1000: {
              items: 1,
            },
          },
        });
        $(".carousel-testimony").owlCarousel({
          center: true,
          loop: true,
          items: 1,
          margin: 30,
          stagePadding: 0,
          nav: true,
          navText: [
            '<span class="ion-ios-arrow-back">',
            '<span class="ion-ios-arrow-forward">',
          ],
          responsive: {
            0: {
              items: 1,
            },
            600: {
              items: 3,
            },
            1000: {
              items: 3,
            },
          },
        });

        $(".carousel-rooms").owlCarousel({
          center: true,
          autoplay: true,
          loop: true,
          items: 1,
          margin: 30,
          stagePadding: 0,
          nav: false,
          navText: [
            '<span class="ion-ios-arrow-back">',
            '<span class="ion-ios-arrow-forward">',
          ],
          responsive: {
            0: {
              items: 1,
            },
            600: {
              items: 3,
            },
            1000: {
              items: 3,
            },
          },
        });
      };
      carousel();

      $("nav .dropdown").hover(
        function () {
          var $this = $(this);
          // 	 timer;
          // clearTimeout(timer);
          $this.addClass("show");
          $this.find("> a").attr("aria-expanded", true);
          // $this.find('.dropdown-menu').addClass('animated-fast fadeInUp show');
          $this.find(".dropdown-menu").addClass("show");
        },
        function () {
          var $this = $(this);
          // timer;
          // timer = setTimeout(function(){
          $this.removeClass("show");
          $this.find("> a").attr("aria-expanded", false);
          // $this.find('.dropdown-menu').removeClass('animated-fast fadeInUp show');
          $this.find(".dropdown-menu").removeClass("show");
          // }, 100);
        }
      );

      $("#dropdown04").on("show.bs.dropdown", function () {
        console.log("show");
      });

      // scroll
      var scrollWindow = function () {
        $(window).scroll(function () {
          var $w = $(this),
            st = $w.scrollTop(),
            navbar = $(".ftco_navbar"),
            sd = $(".js-scroll-wrap");

          if (st > 150) {
            if (!navbar.hasClass("scrolled")) {
              navbar.addClass("scrolled");
            }
          }
          if (st < 150) {
            if (navbar.hasClass("scrolled")) {
              navbar.removeClass("scrolled sleep");
            }
          }
          if (st > 350) {
            if (!navbar.hasClass("awake")) {
              navbar.addClass("awake");
            }

            if (sd.length > 0) {
              sd.addClass("sleep");
            }
          }
          if (st < 350) {
            if (navbar.hasClass("awake")) {
              navbar.removeClass("awake");
              navbar.addClass("sleep");
            }
            if (sd.length > 0) {
              sd.removeClass("sleep");
            }
          }
        });
      };
      scrollWindow();

      var counter = function () {
        $("#section-counter").waypoint(
          function (direction) {
            if (
              direction === "down" &&
              !$(this.element).hasClass("ftco-animated")
            ) {
              var comma_separator_number_step = $.animateNumber.numberStepFactories.separator(
                ","
              );
              $(".number").each(function () {
                var $this = $(this),
                  num = $this.data("number");
                console.log(num);
                $this.animateNumber(
                  {
                    number: num,
                    numberStep: comma_separator_number_step,
                  },
                  7000
                );
              });
            }
          },
          { offset: "95%" }
        );
      };
      counter();

      var contentWayPoint = function () {
        var i = 0;
        $(".ftco-animate").waypoint(
          function (direction) {
            if (
              direction === "down" &&
              !$(this.element).hasClass("ftco-animated")
            ) {
              i++;

              $(this.element).addClass("item-animate");
              setTimeout(function () {
                $("body .ftco-animate.item-animate").each(function (k) {
                  var el = $(this);
                  setTimeout(
                    function () {
                      var effect = el.data("animate-effect");
                      if (effect === "fadeIn") {
                        el.addClass("fadeIn ftco-animated");
                      } else if (effect === "fadeInLeft") {
                        el.addClass("fadeInLeft ftco-animated");
                      } else if (effect === "fadeInRight") {
                        el.addClass("fadeInRight ftco-animated");
                      } else {
                        el.addClass("fadeInUp ftco-animated");
                      }
                      el.removeClass("item-animate");
                    },
                    k * 50,
                    "easeInOutExpo"
                  );
                });
              }, 100);
            }
          },
          { offset: "95%" }
        );
      };
      contentWayPoint();

      // navigation
      var OnePageNav = function () {
        $(".smoothscroll[href^='#'], #ftco-nav ul li a[href^='#']").on(
          "click",
          function (e) {
            e.preventDefault();

            var hash = this.hash,
              navToggler = $(".navbar-toggler");
            $("html, body").animate(
              {
                scrollTop: $(hash).offset().top,
              },
              700,
              "easeInOutExpo",
              function () {
                window.location.hash = hash;
              }
            );

            if (navToggler.is(":visible")) {
              navToggler.click();
            }
          }
        );
        $("body").on("activate.bs.scrollspy", function () {
          console.log("nice");
        });
      };
      OnePageNav();

      // magnific popup
      $(".image-popup").magnificPopup({
        type: "image",
        closeOnContentClick: true,
        closeBtnInside: false,
        fixedContentPos: true,
        mainClass: "mfp-no-margins mfp-with-zoom", // class to remove default margin from left and right side
        gallery: {
          enabled: true,
          navigateByImgClick: true,
          preload: [0, 1], // Will preload 0 - before current, and 1 after the current image
        },
        image: {
          verticalFit: true,
        },
        zoom: {
          enabled: true,
          duration: 300, // don't foget to change the duration also in CSS
        },
      });

      $(".popup-youtube, .popup-vimeo, .popup-gmaps").magnificPopup({
        disableOn: 700,
        type: "iframe",
        mainClass: "mfp-fade",
        removalDelay: 160,
        preloader: false,

        fixedContentPos: false,
      });

      $(".checkin_date, .checkout_date").datepicker({
        format: "m/d/yyyy",
        autoclose: true,
      });
    })(jQuery);
  }
}
