import { Component, OnInit } from '@angular/core';
import { JarwisService } from 'src/app/services/jarwis.service';
import { TokenService } from 'src/app/services/token.service';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { Ng2IzitoastService } from 'ng2-izitoast';
declare var $: any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  
  public loggedIn: boolean;
  public error = [];

  public loginform = {
    email: null,
    password: null
  };

  public signupform = {
    email: null,
    name: null,
    password: null,
    password_confirmation: null
  };

  constructor(
    private Jarwis: JarwisService,
    private Token: TokenService,
    private router: Router,
    private Auth: AuthService,
    private iziToast: Ng2IzitoastService
  ) {
    this.Auth.authStatus.subscribe(value => this.loggedIn = value);
  }

  ngOnInit() {
    this.Auth.authStatus.subscribe(value => this.loggedIn = value);
    $('.navbar-nav>li>a').on('click', function(){
      $('.navbar-collapse').collapse('hide');
    });
  }

  onLogin() {
    this.Jarwis.login(this.loginform).subscribe(
      data => this.handleResponse(data),
      error => this.handleError(error)
    );
  }

  onSignup() {
    this.Jarwis.login(this.signupform).subscribe(
      data => this.handleResponse(data),
      error => this.handleError(error)
    );
  }

  handleResponse(data) {
    this.Token.set(data.token);
    // console.log(data);
    this.Auth.changeAuthStatus(true);
    $('#auth-modal').modal('hide');
    this.router.navigateByUrl('/portal');
  }

  handleError(error) {
    // console.log(error.error);
    this.iziToast.show({title: error.error.error});
    this.error = error.error.error;
  }

  logout(event: MouseEvent) {
    event.preventDefault();
    this.Token.remove();
    this.Auth.changeAuthStatus(false);
    this.router.navigateByUrl('/');
  }
}
