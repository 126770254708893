import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-reservation',
  templateUrl: './reservation.component.html',
  styleUrls: ['./reservation.component.css']
})
export class ReservationComponent implements OnInit {

  private sub: any;

  private parentRouteId: number;
  
  constructor(
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    console.log(this.route.data);
    // console.log(this.route.params);
  }

}
