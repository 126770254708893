import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { JarwisService } from 'src/app/services/jarwis.service';
import Swal from "sweetalert2";

@Component({
  selector: 'app-payment-gateways',
  templateUrl: './payment-gateways.component.html',
  styleUrls: ['./payment-gateways.component.css']
})
export class PaymentGatewaysComponent implements OnInit, AfterViewInit {
  gateways: any;

  constructor(
    private Jarwis:JarwisService,
    private router: Router,

  ) { }
  ngAfterViewInit() {
    this.Jarwis.getURI('select-gateway').subscribe(
      (res) => {
        if(res["success"] === true){
          this.gateways = res['data'];
          console.log(this.gateways);
        }else if(res["success"] === false){
          Swal.fire("Failed!", "Something went wrong", "warning");
          this.router.navigate(['checkout']);
        }
      },
      (err) => {
        console.log("Error occured");
      }
    );
  }

  ngOnInit() {
  }

  selectPayment(id: number){
    this.router.navigate(["/payments/payment-preview", id]);
  }

}
