import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { OwlModule } from 'ngx-owl-carousel';
import { ThemeModule } from './theme/theme.module';
import { HttpClientModule } from '@angular/common/http';
import { JwtModule } from '@auth0/angular-jwt';
import { JarwisService } from './services/jarwis.service';
import { TokenService } from './services/token.service';
import { AuthService } from './services/auth.service';
import { AuthorizationService } from './services/authorization.service';
import { AfterLoginService } from './services/after-login.service';
import { BeforeLoginService } from './services/before-login.service';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { BookingService } from './services/booking.service';

export function tokenGetter() {
  return localStorage.getItem('access_token');
}

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    SweetAlert2Module,
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter,
        whitelistedDomains: [
          'localhost:8000',
          'localhost:8080',
          'localhost:80',
          'localhost:443',
          '172.16.88.25:8000',
          'api.mainahighwayhotel.com:443',
          'api.mainahighwayhotel.com',
          'api.hotel.mainahighwayhotel.com:443',
          'api.hotel.mainahighwayhotel.com'
        ],
        blacklistedRoutes: ['torrent.com']
      }
    }),
    ThemeModule,
    OwlModule
  ],
  providers: [
    JarwisService,
    TokenService,
    AuthService,
    AuthorizationService,
    AfterLoginService,
    BeforeLoginService,
    BookingService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
