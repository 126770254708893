import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthorizationGuard } from './guard/authorization.guard';
import { ThemeModule } from './theme/theme.module';

const routes: Routes = [
  {
    path: '',
    // canActivateChild: [AuthorizationGuard],
    children: [
      // routes here
      // { path: 'portal',
        // component: PortalComponent,
        // loadChildren: () =>  PortalModule
        // canActivate: [AfterLoginService],
        // data: {
        //   allowedRoles: ['root', 'admin', 'cashier', 'director', 'tenant']
        // }
      // },
      {
        path: '',
        // component: ThemeComponent,
        loadChildren: () =>  ThemeModule,
      },
      {
        path: '**',
        redirectTo: '',
        pathMatch: 'full'
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
