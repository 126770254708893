import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class JarwisService {
  /* private baseUrl = 'http://localhost:8080/api';
  private webUrl = 'http://localhost:8080'; */
  // Internet Points
  /* private baseUrl = 'http://api.mainahighwayhotel.com/api';
  private webUrl = 'http://api.mainahighwayhotel.com/'; */
  private baseUrl = 'https://api.hotel.mainahighwayhotel.com/api';
  private webUrl = 'https://api.hotel.mainahighwayhotel.com/';

  constructor(private http: HttpClient) { }

  signup(data) {
    return this.http.post(`${this.baseUrl}/auth/signup`, data);
  }

  login(data) {
    return this.http.post(`${this.baseUrl}/auth/login`, data);
  }

  sendPasswordResetLink(data) {
    return this.http.post(`${this.baseUrl}/sendPasswordResetLink`, data);
  }

  changePassword(data) {
    return this.http.post(`${this.baseUrl}/resetPassword`, data);
  }
  // Index URI
  getURI(url) {
    return this.http.get(`${this.baseUrl}/${url}`);
  }

  // Create URI
  createURI(url) {
    return this.http.get(`${this.baseUrl}/${url}/create`);
  }

  // Store URI
  postURI(url, data) {
    return this.http.post(`${this.baseUrl}/${url}`, data);
  }

  // Show URI
  showURI(url, id) {
    return this.http.get(`${this.baseUrl}/${url}/${id}`);
  }

  // Edit URI
  editURI(url, id) {
    return this.http.get(`${this.baseUrl}/${url}/${id}/edit`);
  }

  // Update URI
  putURI(url, id, data) {
    return this.http.put(`${this.baseUrl}/${url}/${id}`, data);
  }

  // Delete URI
  deleteURI(url, id) {
    return this.http.delete(`${this.baseUrl}/${url}/${id}`);
  }

  // Edit URI
  editWebURI(url, id) {
    return this.http.get(`${this.webUrl}/${url}/${id}/edit`);
  }

}
