interface Scripts {
    name: string;
    src: string;
}
export const ScriptStore: Scripts[] = [
    {name: 'jquery', src: './../assets/islagrande/js/jquery.min.js'},
    {name: 'migrate', src: './../assets/islagrande/js/jquery-migrate-3.0.1.min.js'},
    {name: 'popper', src: './../assets/islagrande/js/popper.min.js'},
    {name: 'bootstrapjs', src: './../assets/islagrande/js/bootstrap.min.js'},
    {name: 'easing', src: './../assets/islagrande/js/jquery.easing.1.3.js'},
    {name: 'waypoints', src: './../assets/islagrande/js/jquery.waypoints.min.js'},
    {name: 'stellar', src: './../assets/islagrande/js/jquery.stellar.min.js'},
    {name: 'aos', src: './../assets/islagrande/js/aos.js'},
    {name: 'bdatepicker', src: './../assets/islagrande/js/bootstrap-datepicker.js'},
    {name: 'jtimepicker', src: './../assets/islagrande/js/jquery.timepicker.min.js'},
    {name: 'scrollax', src: './../assets/islagrande/js/scrollax.min.js'},
    {name: 'google-map', src: './../assets/islagrande/js/google-map.js'},
    {name: 'main', src: './../assets/islagrande/js/main.js'},
];
