import { Component, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { FormGroup, FormBuilder, FormControl } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { JarwisService } from "src/app/services/jarwis.service";
import Swal from "sweetalert2";

@Component({
  selector: "app-reservation-form",
  templateUrl: "./reservation-form.component.html",
  styleUrls: ["./reservation-form.component.css"],
})
export class ReservationFormComponent implements OnInit, OnChanges {

  @Input() room_details: any;
  
  public error = [];
  room_info: any = null;
  reserveForm: FormGroup;
  id: any;
  room_available: number;
  nights_available: number;
  available: any;
  confirm: boolean;
  constructor(
    public fb: FormBuilder,
    private router: Router,
    private Jarwis: JarwisService,
    private _Activatedroute: ActivatedRoute
  ) {}

  /**
  ngOnChanges(changes: SimpleChanges) {
    for (const propName in changes) {
      if (changes.hasOwnProperty(propName)) {
        switch (propName) {
          case 'room_details': {
            this.doSomething(changes.currentValue)
          }
        }
      }
    }
  }
  **/

  ngOnChanges(changes: SimpleChanges) {
  
    // if (changes.hero.previousValue && changes.hero.previousValue.name !== changes.hero.currentValue.name) {
    //   oldHero = changes.hero.previousValue.name;
    // }

    if (changes.room_details.previousValue) {
      // console.log('Old hero is: ', changes.hero.previousValue.name);
      this.room_info = changes.room_details.previousValue;
      console.log(this.room_info);
    }
    
  }


  ngOnInit() {
    console.log(this.room_details)
    this.confirm = false;
    this.id = this._Activatedroute.snapshot.paramMap.get("id");
    console.log(this.id);
    let data_source = localStorage.getItem("booking_dates");
    if (data_source !== null) {
      this.reserveForm = this.fb.group({
        checkin_date: new FormControl(data_source["checkin_date"]),
        checkout_date: new FormControl(data_source["checkout_date"]),
        // room_type: new FormControl(this.selectedBooking['search']['']),
        adults: new FormControl(data_source["adults"]),
        kids: new FormControl(data_source["kids"]),
      });
    } else {
      this.reserveForm = this.fb.group({
        client_name: new FormControl(""),
        client_email: new FormControl(""),
        client_phone_number: new FormControl(""),
        checkout_date: new FormControl(""),
        // room_type: new FormControl(''),
        adults: new FormControl(""),
        kids: new FormControl(""),
      });
    }
    this.reserveForm = this.fb.group({
      client_name: new FormControl(""),
      client_email: new FormControl(""),
      client_phone_number: new FormControl(""),
      checkin_date: new FormControl(""),
      checkout_date: new FormControl(""),
      // room_type: new FormControl(''),
      adults: new FormControl(""),
      kids: new FormControl(""),
    });
    this.room_available = 1;
    this.nights_available = 1;

    this.reserveForm
      .get("checkin_date")
      .valueChanges.subscribe((selectedValue) => {
        this.confirm = false;
        this.checkAvailable();
      });
    this.reserveForm
      .get("checkout_date")
      .valueChanges.subscribe((selectedValue) => {
        this.confirm = false;
        this.checkAvailable();
      });
    this.reserveForm.get("adults").valueChanges.subscribe((selectedValue) => {
      this.confirm = false;
      this.checkAvailable();
    });
    this.reserveForm.get("kids").valueChanges.subscribe((selectedValue) => {
      this.confirm = false;
      this.checkAvailable();
    });
  }
  checkAvailable() {
    console.log("In calc");
    // $('#message_div').addClass('d-none');
    if(this.reserveForm.value.checkin_date !== null && this.reserveForm.value.checkout_date !== null && this.reserveForm.value.adults !== null) {
      const url =
      "room-details/"+ this.id +"/check-available?arrival=" +
      this.dateConvert(this.reserveForm.value.checkin_date) +
      "&departure=" +
      this.dateConvert(this.reserveForm.value.checkout_date) +
      "&children=" +
      this.reserveForm.value.kids +
      "&adults=" +
      this.reserveForm.value.adults;

      this.Jarwis.getURI(url).subscribe(
        (res) => {
          console.log(res);
          if (res["status"] === "success") {
            this.room_available = res["data"]["number_of_room"];
            this.nights_available = res["data"]["total_night"];
            this.available = res["data"]["available"];
            console.info(this.room_available,this.nights_available,this.available)
            /* Swal.fire("Accomodation ", "is available !", "success").then(
              function () {
                // this.router.navigate(["rooms"]);
              }
            );*/
            // this.router.navigate(['rooms']);
          } else {
            // Swal.fire("Failed!", "Something went wrong", "warning");
          }
          // this.reserveForm.reset();
        },
        (err) => {
          console.log("Error occured");
        }
      );


      /*
      success:function (res) {
        number_of_room = res.data.number_of_room;
        total_night = res.data.total_night;
        available = res.data.available;
       setData();
      }
      */
    }
  }
  confirmReservation(){
    this.checkAvailable();
    if(this.reserveForm.valid){
      this.confirm = true;
    }
  }
  dateConvert(req_date) {
    var date = new Date(req_date);
    var year = date.getFullYear();
    var month = date.getMonth() + 1; //getMonth is zero based;
    var day = date.getDate();
    var formatted = year + "/" + month + "/" + day;
    return formatted;
  }
  onSubmit(nform: FormGroup) {
    console.log("Valid?", nform.valid); // true or false
    let form = {
      "name": nform.value.client_name,
      "email": nform.value.client_email,
      "phone": nform.value.client_phone_number,
      "adult": nform.value.adults,
      "children": nform.value.kids,
      "arrival": nform.value.checkin_date,
      "departure": nform.value.checkout_date
     }
    this.Jarwis.postURI('booking/'+ this.id, form).subscribe(res => {
      form = null;
      console.log(res);
      if(res['status'] === 200){
        if(res['success'] === true){
          localStorage.setItem('reservation', JSON.stringify(res));
          this.router.navigate(['/checkout']);
        } else if (res['success'] === false){
          Swal.fire("Opps!" , res['message'], "success");
        }

			  // Swal.fire("Message Sent" , res['message'], "success");
			  // this.iziToast.show({title: "Welcome", message: res['message']});
      } else {
			  // this.iziToast.show({title: "Failed", message: res['message']});
      }
      // this.reserveForm.reset();
    }, err => {
      console.log('Error occured', err);
		});
  }
}
