import { Injectable } from '@angular/core';

@Injectable()
export class TokenService {
  private iss = {
    // login: 'http://localhost:8000/api/auth/login',
    // signup: 'http://localhost:8000/api/auth/signup'
    // login: 'http://localhost:8080/api/auth/login',
    // signup: 'http://localhost:8080/api/auth/signup'
  };

  constructor() { }

  handle(token, username) {
    this.set(token);
    this.setUsername(username);
  }

  handleRole(token, role, username) {
    this.set(token);
    this.setRole(role);
    this.setUsername(username);
  }
  set(token) {
    localStorage.setItem('token', token);
    localStorage.setItem('access_token', token);
  }

  setRole(role) {
    localStorage.setItem('role', role);
    localStorage.setItem('role_name', role.name);
  }

  setUsername(username) {
    localStorage.setItem('username', username);
  }

  get() {
    return localStorage.getItem('token');
  }

  getRole() {
    return localStorage.getItem('role_name');
  }

  getUsername() {
    return localStorage.getItem('username');
  }

  remove() {
    localStorage.removeItem('token');
    localStorage.removeItem('role');
    localStorage.removeItem('role_name');
    localStorage.removeItem('username');
  }

  isValid() {
    const token = this.get();
    if (token) {
      const payload = this.payload(token);
      if (payload) {
        return Object.values(this.iss).indexOf(payload.iss) > -1 ? true : false;
      }
    }
    return false;
  }

  payload(token) {
    const payload = token.split('.')[1];
    return this.decode(payload);
  }

  decode(payload) {
    return JSON.parse(atob(payload));
  }

  loggedIn() {
    return this.isValid();
  }
}
